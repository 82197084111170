import { api } from "../../api";
import { RoutesWithoutParams, routesWithParams } from "../../hooks/useFetch/getRoutes";
import { ICategoryResponse } from "../../types/categories/categoryTypes";
import { IGenericResponse } from "../../types/responseGeneric/types";

export const copyBehaviorService = {
    create: async (payload: { id_user: string; hit: number }): Promise<IGenericResponse> => {
        const response = await api.post(RoutesWithoutParams.CreateCopyBehavior, payload);
        return response.data.data;
    },

    update: async (idUser: string, payload: { hit: number; id_user: string }): Promise<ICategoryResponse> => {
        const response = await api.put(routesWithParams.updateCopyBehavior(idUser), payload);
        return response.data.data;
    }
};
