// get routes without params
export enum RoutesWithoutParams {
    AllUser = "/api/users",
    Session = "/api/session/ywkrqtdztoimgjxy",
    //Qtest
    UpdateQTest = "/api/qtests-update",
    //Category
    QuestionByCategory = "/api/questions-category-qty",
    AllCategory = "/api/categories",
    CreateCategory = "/api/categories-create",
    //Questions
    FindAllQuestions = "/api/questions",
    CreateQuestion = "/api/questions-create",
    //Test
    CreateTest = "/api/tests-create",
    //Answers
    AllAnswer = "/api/answers-with-question",
    CreateAnswer = "/api/answers-create",
    //Flashcard
    CreateFlashard = "/api/apps/flashcards",
    AllFlashcards = "/api/apps/flashcards",
    //Flashcard
    CreateFlashardCategorized = "/api/apps/flashcards-categorized",
    //Copy Behavior
    CreateCopyBehavior = "/api/admin/copy-behavior"
}

export const routesWithParams = {
    // user
    updateUser: (iduser: string): string => {
        return `/api/users-update/${iduser}`;
    },
    // Tests
    findAllTestByUser: (iduser: string): string => {
        return `/api/tests/${iduser}`;
    },
    findOneTest: (idTest: string): string => {
        return `/api/tests_specific/${idTest}`;
    },

    // QTest
    findAllQTestByTestId: (idTest: string): string => {
        return `/api/qtests/${idTest}`;
    },
    // Question
    findOneQuestion: (idQuestion: number): string => {
        return `/api/questions/${idQuestion}`;
    },
    findUnusedQuestions: (iduser: string, category: number): string => {
        return `/api/questions-unused-category/${iduser}/${category}`;
    },
    findAllByCategory: (category: number): string => {
        return `/api/questions-by-category-qty/${category}`;
    },
    findUnusedByMultipleCategory: (iduser: string, category: number[], type: string): string => {
        return `/api/questions-unused-multiple-category/${iduser}/[${category.toString()}]/${type}`;
    },
    updateQuestion: (idQuestion: number): string => {
        return `/api/questions-update/${idQuestion}`;
    },
    deleteQuestion: (idQuestion: number): string => {
        return `/api/questions-delete/${idQuestion}`;
    },
    // Categoreis
    updateCategory: (idcategory: number): string => {
        return `/api/categories-update/${idcategory}`;
    },
    deleteCategory: (idcategory: number): string => {
        return `/api/categories-delete/${idcategory}`;
    },
    // Answer
    updateAnswer: (idAnswer: number): string => {
        return `/api/answers-update/${idAnswer}`;
    },
    deleteAnswer: (idAnswer: number): string => {
        return `/api/answers-delete/${idAnswer}`;
    },
    // Reports User
    qBankUsageReport: (idUser: string): string => {
        return `/api/report-user/qbank-usage/${idUser}`;
    },
    scoreUserReport: (idUser: string): string => {
        return `/api/report-user/score/${idUser}`;
    },
    rankUserReport: (idUser: string): string => {
        return `/api/report-user/global-rank/${idUser}`;
    },
    categoryUserReport: (idUser: string): string => {
        return `/api/report-user/category-data/${idUser}`;
    },
    performanceUserReport: (idUser: string, initial: string, final: string, idCategory: number): string => {
        return `/api/report-user/performance-date/${idUser}/${initial}/${final}/${idCategory}`;
    },
    questionByCategoryAndType: (type: string): string => {
        return `/api/questions-category-qty/${type}`;
    },
    //Flashcard
    findOneFlashcard: (id: number): string => {
        return `/api/apps/flashcards/${id}`;
    },
    updateFlashcard: (id: number): string => {
        return `/api/apps/flashcards/${id}`;
    },
    destroyFlashcard: (id: number): string => {
        return `/api/apps/flashcards/${id}`;
    },
    //FlashcardCategorized
    findOneFlashcardCategorized: (id: number): string => {
        return `/api/apps/flashcards-categorized/${id}`;
    },
    updateFlashcardCategorized: (id: number): string => {
        return `/api/apps/flashcards-categorized/${id}`;
    },
    destroyFlashcardCategorized: (id: number): string => {
        return `/api/apps/flashcards-categorized/${id}`;
    },
    //Copy Behavior
    updateCopyBehavior: (idUser: string): string => {
        return `/api/admin/copy-behavior/${idUser}`;
    }
};
