import { PropsWithChildren, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "./store";
import {
    toggleRTL,
    toggleTheme,
    toggleLocale,
    toggleMenu,
    toggleLayout,
    toggleAnimation,
    toggleNavbar,
    toggleSemidark
} from "./store/themeConfigSlice";
import store from "./store";
import useCopyBehavior from "./hooks/copy_behavior/useCopyBehavior";

function App({ children }: PropsWithChildren) {
    const themeConfig = useSelector((state: IRootState) => state.themeConfig);
    const dispatch = useDispatch();

    const { createCopyBehavior } = useCopyBehavior();

    useEffect(() => {
        dispatch(toggleTheme(localStorage.getItem("theme") || themeConfig.theme));
        dispatch(toggleMenu(localStorage.getItem("menu") || themeConfig.menu));
        dispatch(toggleLayout(localStorage.getItem("layout") || themeConfig.layout));
        dispatch(toggleRTL(localStorage.getItem("rtlClass") || themeConfig.rtlClass));
        dispatch(toggleAnimation(localStorage.getItem("animation") || themeConfig.animation));
        dispatch(toggleNavbar(localStorage.getItem("navbar") || themeConfig.navbar));
        dispatch(toggleLocale(localStorage.getItem("i18nextLng") || themeConfig.locale));
        dispatch(toggleSemidark(localStorage.getItem("semidark") || themeConfig.semidark));
    }, [
        dispatch,
        themeConfig.theme,
        themeConfig.menu,
        themeConfig.layout,
        themeConfig.rtlClass,
        themeConfig.animation,
        themeConfig.navbar,
        themeConfig.locale,
        themeConfig.semidark
    ]);

    useEffect(() => {
        const blockRightClick = (e: { preventDefault: () => void }) => {
            e.preventDefault();
        };

        const blockF12 = (e: { preventDefault: () => void; key: string }) => {
            if (e.key === "F12") {
                e.preventDefault();
            }
        };
        const blockCopy = (e: KeyboardEvent) => {
            if (e.ctrlKey && e.key === "c") {
                e.preventDefault();
                alert("Copies are not allowed.");
                navigator.clipboard.writeText("copies are not allowed.");
                createCopyBehavior({ hit: 1 });
            }

            if (e.shiftKey && e.key === "5") {
                e.preventDefault();
                alert("Copies are not allowed.");
                createCopyBehavior({ hit: 1 });
            }
        };

        const applyNoSelectStyle = () => {
            const style = document.createElement("style");
            style.textContent = `
                * {
                    user-select: none !important;
                    -webkit-user-select: none !important;
                    -ms-user-select: none !important;
                }
            `;
            document.head.appendChild(style);
        };

        applyNoSelectStyle();
        document.addEventListener("contextmenu", blockRightClick);
        window.addEventListener("keydown", blockF12);
        window.addEventListener("keydown", blockCopy);
        return () => {
            document.removeEventListener("contextmenu", blockRightClick);
            window.removeEventListener("keydown", blockF12);
            window.removeEventListener("keydown", blockCopy);
        };
    }, []);

    return (
        <div
            className={`${(store.getState().themeConfig.sidebar && "toggle-sidebar") || ""} ${themeConfig.menu} ${
                themeConfig.layout
            } ${themeConfig.rtlClass} main-section antialiased relative font-nunito text-sm font-normal`}
        >
            {children}
        </div>
    );
}

export default App;
