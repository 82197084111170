import PerfectScrollbar from "react-perfect-scrollbar";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { toggleSidebar } from "../../store/themeConfigSlice";
import AnimateHeight from "react-animate-height";
import { IRootState } from "../../store";
import { useState, useEffect } from "react";

const Sidebar = () => {
    const userRole = useSelector((state: IRootState) => state.userConfig.role);
    const isDark = useSelector((state: IRootState) => state.themeConfig.theme === "dark");

    const [currentMenu, setCurrentMenu] = useState<string>("");
    const themeConfig = useSelector((state: IRootState) => state.themeConfig);
    const semidark = useSelector((state: IRootState) => state.themeConfig.semidark);
    const location = useLocation();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const toggleMenu = (value: string) => {
        setCurrentMenu((oldValue) => {
            return oldValue === value ? "" : value;
        });
    };

    useEffect(() => {
        const selector = document.querySelector('.sidebar ul a[href="' + window.location.pathname + '"]');
        if (selector) {
            selector.classList.add("active");
            const ul: any = selector.closest("ul.sub-menu");
            if (ul) {
                let ele: any = ul.closest("li.menu").querySelectorAll(".nav-link") || [];
                if (ele.length) {
                    ele = ele[0];
                    setTimeout(() => {
                        ele.click();
                    });
                }
            }
        }
    }, []);

    useEffect(() => {
        if (window.innerWidth < 1024 && themeConfig.sidebar) {
            dispatch(toggleSidebar());
        }
    }, [location, dispatch, themeConfig.sidebar]);

    return (
        <div className={isDark ? "dark" : ""}>
            <nav
                className={`sidebar fixed min-h-screen h-full top-0 bottom-0 w-64 shadow-lg z-50 transition-all duration-300 ${
                    isDark ? "bg-gray-900 text-gray-100" : "bg-white text-gray-800"
                }`}
            >
                <div className="h-full flex flex-col">
                    <div
                        className={`flex justify-between items-center px-6 py-5 border-b ${
                            isDark ? "border-gray-700" : "border-gray-200"
                        }`}
                    >
                        <NavLink to="/" className="flex items-center space-x-3">
                            <img
                                className="w-8 h-8 rounded-full"
                                src="/assets/images/logo-redonda-small.png"
                                alt="logo"
                            />
                            <span className="text-xl font-bold">{t("name_app")}</span>
                        </NavLink>

                        <button
                            type="button"
                            className={`p-2 rounded-full ${
                                isDark ? "hover:bg-gray-700" : "hover:bg-gray-200"
                            } transition duration-300`}
                            onClick={() => dispatch(toggleSidebar())}
                        ></button>
                    </div>
                    <PerfectScrollbar className="flex-grow">
                        <ul className="py-4 px-4 space-y-1">
                            <li>
                                <NavLink
                                    to="/"
                                    className={`flex items-center p-3 rounded-lg ${
                                        isDark ? "hover:bg-gray-800" : "hover:bg-gray-100"
                                    } transition duration-300`}
                                >
                                    <span className="font-medium ">{t("dashboard")}</span>
                                </NavLink>
                            </li>

                            <li className="pt-5">
                                <h2
                                    className={`px-3 text-md font-semibold uppercase tracking-wider ${
                                        isDark ? "text-gray-400" : "text-black"
                                    }`}
                                >
                                    {t("apps")}
                                </h2>
                            </li>

                            <li>
                                <NavLink
                                    to="/apps/create_test"
                                    className={`flex items-center p-3 rounded-lg ${
                                        isDark ? "hover:bg-gray-800" : "hover:bg-gray-100"
                                    } transition duration-300`}
                                >
                                    <span className="font-medium">{t("create_test")}</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to="/apps/previous_tests"
                                    className={`flex items-center p-3 rounded-lg ${
                                        isDark ? "hover:bg-gray-800" : "hover:bg-gray-100"
                                    } transition duration-300`}
                                >
                                    <span className="font-medium">{t("previous_tests")}</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to="/apps/flashcard"
                                    className={`flex items-center p-3 rounded-lg ${
                                        isDark ? "hover:bg-gray-800" : "hover:bg-gray-100"
                                    } transition duration-300`}
                                >
                                    <span className="font-medium">{t("flashcard")}</span>
                                </NavLink>
                            </li>

                            {userRole === 1 && (
                                <>
                                    <li className="pt-5">
                                        <h2
                                            className={`px-3 text-md font-semibold uppercase tracking-wider ${
                                                isDark ? "text-gray-400" : "text-black"
                                            }`}
                                        >
                                            {t("admin_access")}
                                        </h2>
                                    </li>
                                    <li>
                                        <NavLink
                                            to="/admin/users"
                                            className={`flex items-center p-3 rounded-lg ${
                                                isDark ? "hover:bg-gray-800" : "hover:bg-gray-100"
                                            } transition duration-300`}
                                        >
                                            <span className="font-medium">{t("users")}</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <button
                                            type="button"
                                            className={`flex items-center justify-between w-full p-3 rounded-lg ${
                                                isDark ? "hover:bg-gray-800" : "hover:bg-gray-100"
                                            } transition duration-300 ${
                                                currentMenu === "questions"
                                                    ? isDark
                                                        ? "bg-gray-800"
                                                        : "bg-gray-100"
                                                    : ""
                                            }`}
                                            onClick={() => toggleMenu("questions")}
                                        >
                                            <div className="flex items-center">
                                                <span className="font-medium">{t("questions")}</span>
                                            </div>
                                        </button>
                                        <AnimateHeight duration={300} height={currentMenu === "questions" ? "auto" : 0}>
                                            <ul className="pl-12 py-2 space-y-1">
                                                <li>
                                                    <NavLink
                                                        to="/admin/questions/category"
                                                        className={`block py-2 px-3 rounded-lg ${
                                                            isDark ? "hover:bg-gray-800" : "hover:bg-gray-100"
                                                        } transition duration-300`}
                                                    >
                                                        {t("category")}
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink
                                                        to="/admin/questions/questions"
                                                        className={`block py-2 px-3 rounded-lg ${
                                                            isDark ? "hover:bg-gray-800" : "hover:bg-gray-100"
                                                        } transition duration-300`}
                                                    >
                                                        {t("questions")}
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink
                                                        to="/admin/questions/answers"
                                                        className={`block py-2 px-3 rounded-lg ${
                                                            isDark ? "hover:bg-gray-800" : "hover:bg-gray-100"
                                                        } transition duration-300`}
                                                    >
                                                        {t("answers")}
                                                    </NavLink>
                                                </li>
                                            </ul>
                                        </AnimateHeight>
                                    </li>
                                    <li>
                                        <NavLink
                                            to="/admin/flashcards"
                                            className={`flex items-center p-3 rounded-lg ${
                                                isDark ? "hover:bg-gray-800" : "hover:bg-gray-100"
                                            } transition duration-300`}
                                        >
                                            <span className="font-medium">{t("flashcard")}</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to="#"
                                            className={`flex items-center p-3 rounded-lg ${
                                                isDark ? "hover:bg-gray-800" : "hover:bg-gray-100"
                                            } transition duration-300`}
                                        >
                                            <span className="font-medium">{t("reports")}</span>
                                            <span
                                                className={`ml-auto ${
                                                    isDark ? "bg-gray-700 text-gray-200" : "bg-gray-200 text-gray-800"
                                                } text-xs font-semibold px-2 py-1 rounded-full`}
                                            >
                                                Dev
                                            </span>
                                        </NavLink>
                                    </li>
                                </>
                            )}

                            <li className="pt-5">
                                <h2
                                    className={`px-3 text-md font-semibold uppercase tracking-wider ${
                                        isDark ? "text-gray-400" : "text-black"
                                    }`}
                                >
                                    {t("reports")}
                                </h2>
                            </li>
                            <li>
                                <button
                                    type="button"
                                    className={`flex items-center justify-between w-full p-3 rounded-lg ${
                                        isDark ? "hover:bg-gray-800" : "hover:bg-gray-100"
                                    } transition duration-300 ${
                                        currentMenu === "charts" ? (isDark ? "bg-gray-800" : "bg-gray-100") : ""
                                    }`}
                                    onClick={() => toggleMenu("charts")}
                                >
                                    <div className="flex items-center">
                                        <span className="font-medium">{t("charts")}</span>
                                    </div>
                                </button>
                                <AnimateHeight duration={300} height={currentMenu === "charts" ? "auto" : 0}>
                                    <ul className="pl-12 py-2 space-y-1">
                                        <li>
                                            <NavLink
                                                to="/reports/user/general"
                                                className={`block py-2 px-3 rounded-lg ${
                                                    isDark ? "hover:bg-gray-800" : "hover:bg-gray-100"
                                                } transition duration-300`}
                                            >
                                                {t("used_score")}
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink
                                                to="/reports/user/performance"
                                                className={`block py-2 px-3 rounded-lg ${
                                                    isDark ? "hover:bg-gray-800" : "hover:bg-gray-100"
                                                } transition duration-300`}
                                            >
                                                {t("performance")}
                                            </NavLink>
                                        </li>
                                    </ul>
                                </AnimateHeight>
                            </li>

                            <li className="pt-5">
                                <h2
                                    className={`px-3 text-md font-semibold uppercase tracking-wider ${
                                        isDark ? "text-gray-400" : "text-black"
                                    }`}
                                >
                                    {t("user_and_pages")}
                                </h2>
                            </li>
                            <li>
                                <NavLink
                                    to="/users/user-account-settings"
                                    className={`flex items-center p-3 rounded-lg ${
                                        isDark ? "hover:bg-gray-800" : "hover:bg-gray-100"
                                    } transition duration-300`}
                                >
                                    <span className="font-medium">{t("account_settings")}</span>
                                </NavLink>
                            </li>
                        </ul>
                    </PerfectScrollbar>
                </div>
            </nav>
        </div>
    );
};

export default Sidebar;
