import { AxiosError } from "axios";
import { categoryService } from "../../services/category/category.service";
import { ICategory } from "../../types/categories/categoryTypes";
import { IErrorResponse } from "../../types/serverResponse/types";
import { useFetch } from "../useFetch";
import { RoutesWithoutParams } from "../useFetch/getRoutes";
import { useTranslation } from "react-i18next";
import { copyBehaviorService } from "../../services/copy_behavior/copy_behavior.service";
import { useSelector } from "react-redux";
import { IRootState } from "../../store/index";

const useCopyBehavior = () => {
    const { t } = useTranslation();
    const idUser = useSelector((state: IRootState) => state.userConfig.id);

    const createCopyBehavior = async (payload: { hit: number }) => {
        try {
            const response = await copyBehaviorService.create({
                id_user: idUser,
                hit: payload.hit
            });
            return response;
        } catch (e: unknown) {
            const error = e as IErrorResponse;
            throw new Error(`Code ${error.response.data.code}`);
        }
    };

    const updateCopyBehavior = async (idUser: string) => {
        try {
            const response = await copyBehaviorService.update(idUser, {
                id_user: idUser,
                hit: 0
            });
            return response;
        } catch (e: unknown) {
            const error = e as IErrorResponse;
            throw new Error(`${error.response.data.code}`);
        }
    };

    return {
        createCopyBehavior,
        updateCopyBehavior
    };
};

export default useCopyBehavior;
