import { ChangeEvent, Fragment, useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";

import { useTranslation } from "react-i18next";
import { IAnswer } from "../../../types/answers/answerType";
import { ITest } from "../../../types/test_created/testTypes";
import { IQTests } from "../../../types/qTests/qTestsTypes";
import { IQuestion } from "../../../types/questions/questionsType";
import { IDataSubmit } from "../types";
import { LoadingData } from "../../Components";

import Markdown from "react-markdown";

interface IProps {
    index: number;
    dataSubmit: IDataSubmit;
    setDataSubmit: (data: IDataSubmit) => void;
    // setDataSubmit: React.Dispatch<React.SetStateAction<IDataSubmit>>;
    questionData: Partial<IQuestion>;
    testData: Partial<ITest>;
    qTestData: IQTests;
}

const QuestionTestWithouTutor = ({ index, dataSubmit, setDataSubmit, questionData, testData, qTestData }: IProps) => {
    // hooks
    const { t } = useTranslation();

    // states
    const [answers, setAnswers] = useState<[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    // functions
    useEffect(() => {
        if (answers.length === 0) {
            const result = questionData.answers?.sort(() => Math.random() - 0.5);
            setAnswers(result as []);
        }
    }, [index, dataSubmit, setDataSubmit, questionData, testData]);

    useEffect(() => {
        setLoading(false);
    }, [answers]);

    const handleAnswer = (e: ChangeEvent<HTMLInputElement>, correct: boolean | number) => {
        const { name, value } = e.target;

        setDataSubmit({
            ...dataSubmit,
            id_question: questionData.id,
            correct: correct as any,
            [name]: parseInt(value)
        });
    };

    if (loading) return <LoadingData />;

    function renderAnswers(element: IAnswer, key: number) {
        return (
            <Fragment key={key}>
                <label className="flex mt-2 sm:mt-6 cursor-pointer">
                    <input
                        type="radio"
                        name="id_answer"
                        className={`form-radio peer`}
                        value={element.id}
                        defaultChecked={element.id === qTestData.id_answer ? true : false}
                        onChange={(e) => handleAnswer(e, element.correct)}
                    />
                    <span className={`peer-checked:text-black dark:peer-checked:text-white peer-checked:font-bold`}>
                        <Markdown className={"whitespace-pre-wrap"}>{element.description}</Markdown>
                    </span>
                </label>
            </Fragment>
        );
    }

    // render
    return (
        <div>
            <div className="flex-1 items-center justify-between flex-wrap gap-2">
                <div className="flex-col">
                    <div className="flex">
                        <div className="w-full-auto h-auto">
                            <div>
                                <Markdown className={"whitespace-pre-wrap rounded-md "}>
                                    {questionData.description}
                                </Markdown>
                            </div>
                            <div>{answers.length > 0 && <div className="mt-2">{answers.map(renderAnswers)}</div>}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QuestionTestWithouTutor;
