import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
//...
import * as Sentry from "@sentry/react";

// Sentry.init({
//     dsn: "https://f8b00f703272000fdfce20e4be01a912@o4508059553955840.ingest.us.sentry.io/4508065063632896",
//     integrations: [
//         Sentry.browserTracingIntegration(),
//         Sentry.browserProfilingIntegration(),
//         Sentry.replayIntegration()
//     ],
//     // Tracing
//     tracesSampleRate: 1.0, //  Capture 100% of the transactions
//     // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//     tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
//     // Set profilesSampleRate to 1.0 to profile every transaction.
//     // Since profilesSampleRate is relative to tracesSampleRate,
//     // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
//     // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
//     // results in 25% of transactions being profiled (0.5*0.5=0.25)
//     profilesSampleRate: 1.0,
//     // Session Replay
//     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

// Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";

// Tailwind css
import "./tailwind.css";

// i18n (needs to be bundled)
import "./i18n";

// Router
import RouterComponent from "./router/index";

// Redux
import { Provider } from "react-redux";
import store from "./store/index";

// Suspense bright component renderized. Instead, can print fallback with the component while loading children. Ex. fallback=<Loading /> *Necessary create the component

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <Suspense>
            <Provider store={store}>
                <RouterComponent />
            </Provider>
        </Suspense>
    </React.StrictMode>
);
